/* my private helpers*/
// * my private helpers* //
/* global document */
/**
 * querySelector Wrapper
 * @function
 * @param {string} e - Element to select
 * @param {domNode} c - context, defaults to document
 */
function qS(selector) {
  var c = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;

  return c.querySelector(selector);
}

/**
 * querySelectorAll Wrapper
 * @function
 * @param {string} e - Element to select
 * @param {domNode} c - context, defaults to document
 */
function qSA(selector) {
  var c = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;

  var nl = c.querySelectorAll(selector);
  var domNodes = Array.from(nl);
  return domNodes;
}

/**
 * Throws error if elementa are not a valid DOM Node
 * @function
 * @param {nodeList} nodeList - Elements to check
 */
function checkDomNodeList(nodeList) {
  nodeList.forEach(function (node) {
    if (node === null || node === undefined) {
      throw new Error(node + ' is not a valid DOM Node');
    }
  });
}

/**
 * Throws error if element is not a valid DOM Node
 * @function
 * @param {DomNode} DOMNode - Elements to check
 */
function checkDomNode(node, element) {
  if (node === null || node === undefined) {
    return false;
  }
}

/**
 * Fade in Element
 * @function
 * @param {domNode} el - Element to fade
 * @param {integer} duration - Duration of Animation
 * @param {string} display - set display property after fade in
 */
function fadeIn(el, duration) {
  var display = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'block';

  var s = el.style;
  var step = 25 / (duration || 300);
  s.opacity = s.opacity || 0;
  s.display = display;
  (function fade() {
    (s.opacity = parseFloat(s.opacity) + step) > 1 ? s.opacity = 1 : setTimeout(fade, 25);
  })();
}

/**
 * Fade in Element
 * @function
 * @param {array} objects - array of objects to check
 * @param {string} key - the key, which values needs to be compared
 * @param {string} value - values wich must all be the same
 */
function allSameValue(objects, key, value) {
  var val = [];
  objects.forEach(function (obj) {
    val.push(obj[key]);
  });
  return val.every(function (entry) {
    return entry === value;
  });
}

/**
 * Fade out Element, callback on animation complete
 * @function
 * @param {domNode} el - Element to fade
 * @param {integer} duration - Duration of Animation
 * @param {string} cb - Function to be called after animation
 */
function fadeOutCb(el, duration, cb) {
  var s = el.style;
  var step = 25 / (duration || 300);
  s.opacity = s.opacity || 1;
  (function fade() {
    if ((s.opacity -= step) < 0) {
      s.display = 'none';
      return cb();
    }
    return setTimeout(fade, 25);
  })();
}

/**
 * noop helper
 * @function
 */
function noop() {
  return function () {};
}

/**
 * Holds svg icons
 * @object
 */
var icons = {
  burger: '<svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>',
  navDot: '\n<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">\n  <defs>\n    <style>\n      .cls-1, .cls-3 { fill: none; } .cls-1 { stroke: #000; stroke-width: 2px; } .cls-2 { stroke: none; }\n    </style>\n  </defs>\n  <g id="nav_dot">\n    <g id="kreis" class="cls-1" transform="translate(4398 6324)">\n      <circle cx="10" cy="10" r="10" class="cls-2"/>\n      <circle cx="10" cy="10" r="9" class="cls-3"/>\n    </g>\n    <g id="punkt" class="cls-1" transform="translate(4403 6329)">\n      <circle cx="5" cy="5" r="5" class="cls-2"/>\n      <circle cx="5" cy="5" r="4" class="cls-3"/>\n    </g>\n  </g>\n</svg>'
};


/* by Gregor Staab */

export { qS, qSA, allSameValue, fadeIn, fadeOutCb, noop, icons, checkDomNode, checkDomNodeList };
/* by Gregor Staab */
