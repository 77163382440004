import { qS, qSA } from 'gs-util';

export default class {
  constructor(links, insertPosition, wrapperClass = null) {
    this.insertPosition = qS(insertPosition);
    this.wrapperClass = wrapperClass;
    this.links = qSA(links);
    this.init();
  }
  init() {
    // check if nav exists on page
    if (this.links.length === 0) {
      return false;
    }
    this.links.forEach(l => l.addEventListener('click', (e) => {
      e.preventDefault();
      const url = e.target.href;
      if (e.target.classList.contains('js-urlsegment-current-link')) {
        return false;
      }
      this.request(url);
      this.handleClasses(e.target);
    }));
    // click on first link
    const nl = this.links;
    const firstClick = nl[0];
    firstClick.click();
  }
  request(url) {
    const header = new Headers();
    header.append('X-Requested-With', 'XMLHttpRequest')
    fetch(url, { headers: header, credentials: 'include' })
      .then(res => res.text())
      .then((res) => {
      // console.log(res)
        this.prepareMarkup(res);
      });
  }
  prepareMarkup(html) {
    const wrapper = document.createElement('div');
    this.wrapperClass !== null ? wrapper.classList.add(this.wrapperClass) : () => {}
    wrapper.classList.add('js-urlsegment-wrapper');
    wrapper.innerHTML = html;
    this.removeMarkup();
    this.addMarkup(wrapper);
  }
  removeMarkup() {
    this.insertPosition.nextSibling.remove()
  }
  addMarkup(newMarkup) {
    this.insertPosition.insertAdjacentElement('afterend', newMarkup)
  }
  handleClasses(current) {
    const curr = qS('.js-urlsegment-current-link');
    curr != null ? curr.classList.remove('js-urlsegment-current-link') : () => {};
    current.classList.add('js-urlsegment-current-link');
  }
}
