import mobileNav from 'mobile-nav';
import { icons } from 'gs-util';
import Slideshow from './Slideshow';
import Urlsegmentloader from './Urlsegmentloader';

mobileNav.init({ maxWidth: 900, menu: icons.burger, toRight: -400 });

const show = new Slideshow('[data-slides]', 4000, 200);

const mannschaft = new Urlsegmentloader(
  '.js-urlsegment-loader',
  '.mannschaft-nav',
  'mannschaft-container',
);
const anschaffungen = new Urlsegmentloader(
  '.js-ajax-content',
  '.mannschaft-nav',
  'anschaffungen-container',
);
