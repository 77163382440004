import { qS, noop } from 'gs-util';

export default class {
  constructor(slides, duration, minwidth) {
    if (qS(slides) == null) {
      return false;
    }
    this.slides = qS(slides).dataset.slides;
    this.img = qS('.js-slide');
    this.duration = duration;
    this.running = false;
    this.dataUrls = [];
    this.urlIndex = 0;
    this.preCheck(minwidth);
  }
  prepareString() {
    const urlArray = this.slides.split(',');
    urlArray.splice(-1, 1);
    this.loadFiles(urlArray);
  }
  loadFiles(filesArr) {
    filesArr.forEach(el => {
      const header = new Headers();
      header.append('Authorization', `Basic ${btoa('fw:feuerwehR2018!')}`);
      fetch(el, { headers: header })
        .then(res => res.blob())
        .then(res => {
          const img = URL.createObjectURL(res);
          this.dataUrls.push(img);
        })
        .then(() => this.checkFileCount());
    });
  }
  checkFileCount() {
    return new Promise(resolve => {
      this.dataUrls.length >= 3 && this.running === false ? resolve() : noop();
    }).then(() => {
      this.running = true;
      this.loopPics();
    });
  }
  loopPics() {
    const el = this.img;
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, this.duration);
    })
      .then(() => {
        el.classList.toggle('js-slide-fade-out');
        return new Promise(resolve => {
          el.addEventListener('transitionend', () => {
            resolve();
          });
        });
      })
      .then(() => {
        this.img.src = this.getUrl(this.urlIndex);
        el.classList.toggle('js-slide-fade-out');
        return new Promise(resolve => {
          el.addEventListener('transitionend', () => {
            resolve();
          });
        });
      })
      .then(() => {
        this.loopPics();
      });
  }
  getUrl(newIndex) {
    const arrayLength = this.dataUrls.length - 1;
    const url = this.dataUrls[newIndex];
    arrayLength === newIndex ? (this.urlIndex = 0) : (this.urlIndex += 1);
    return url;
  }
  preCheck(minwidth) {
    if (!window.matchMedia(`(min-width:${minwidth}px)`).matches) {
      return false;
    }
    return this.prepareString(this.slides);
  }
} // class end
